import { Button, Icon, UiText } from '@heetch/flamingo-react';
import styled from 'styled-components';
import { theme } from '@styles/theme';

export type CountryFieldProps = {
  label: string;
  flag: string;
  onClick: () => void;
};

const CountryFieldButton = styled(Button).attrs({ variant: 'text' })`
  box-shadow: 0px -1px 0px var(--borderColor);
  border-radius: 0;
  display: flex;
  height: 71px !important;
  padding: 0;
  margin: 0;
  width: 273px;
  color: ${theme.flamingo.color_v3.type.default};
  /* Override Flamingo behavior that adds margins to buttons next to each others */
  & + & {
    margin-left: inherit;
  }
  > span {
    display: flex;
    align-items: center;
    width: 273px;
    justify-content: space-between;
  }
`;

export const CountryField = ({ label, flag, onClick }: CountryFieldProps) => {
  return (
    <CountryFieldButton onClick={onClick}>
      <>{flag}</>
      <UiText textColor={theme.flamingo.color_v3.type.default}>{label}</UiText>
      <Icon icon='IconArrowRight' />
    </CountryFieldButton>
  );
};
