import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from '@/context/translation/TranslationContext';
import { CurrentLocalization } from '@/types/localization';
import { localizationFromString } from '@/utils/localization';
import { LargeHeading } from '@/components/LargeHeading';
import { MediumHeading } from '@/components/MediumHeading';
import { CountrySelectorTitleContainer } from '@/components/country-selection/CountrySelectorTitleContainer';
import { CountryField } from '@/components/country-selection/CountryField';
import { CountryFieldContainer } from '@/components/country-selection/CountryFieldContainer';

type CountryField = {
  value: string;
  label: string;
  flag: string;
};

type CountrySelectorProps = {
  options: CountryField[];
};

export const CountrySelector = ({ options }: CountrySelectorProps) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const [redirected, setRedirected] = useState(false);

  const onSelectLocalization = useCallback(
    (loc: CurrentLocalization | undefined) => {
      if (!loc) return;
      setRedirected(true);
      push({
        pathname: `${loc.country}/${loc.language}`,
      });
    },
    [push],
  );

  useEffect(() => {
    if (!redirected && localStorage.getItem('languagePreference')) {
      const language = localStorage.getItem('languagePreference') || '';
      onSelectLocalization(localizationFromString(language));
    }
  }, [redirected, onSelectLocalization]);

  const selectCountryField = (country: string) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('languagePreference', country);
      onSelectLocalization(localizationFromString(country));
    }
  };

  return (
    <CountrySelectorTitleContainer>
      <LargeHeading css={{ color: 'black' }}>
        {t('how-can-we-help')}
      </LargeHeading>
      <MediumHeading>{t('select-country')}</MediumHeading>
      <CountryFieldContainer>
        {options.map((country: CountryField) => (
          <CountryField
            key={country.value}
            label={country.label}
            flag={country.flag}
            onClick={() => selectCountryField(country.value)}
          />
        ))}
      </CountryFieldContainer>
    </CountrySelectorTitleContainer>
  );
};
