import styled from 'styled-components';
import { theme } from '@styles/theme';

export const CountryFieldContainer = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 360px;
  column-gap: 94px;
  --borderColor: ${theme.flamingo.color_v3.ui.tertiary};
  ${theme.media.mobile} {
    margin-top: 0;
    display: flex;
    height: auto;
  }
`;
