import styled from 'styled-components';
import { Heading } from '@heetch/flamingo-react';
import { theme } from '@styles/theme';

export const MediumHeading = styled(Heading)`
  color: currentColor;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  margin: 0;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  ${theme.media.tablet} {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;

    /* or 112% */
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  ${theme.media.mobile} {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;

    /* or 100% */
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
`;
