import { useMemo } from 'react';
import { GetStaticProps } from 'next';
import countryCodeToFlagEmoji from 'country-code-to-flag-emoji';
import { Translations } from '@/context/translation/TranslationProvider';
import { LocalizationInfo } from '@/types/localization';
import { getLocalizedPageProps } from '@/utils/localized_page';
import { localizationToString } from '@/utils/localization';
import { draftEnabled } from '@/utils/draftEnabled';
import { Layout } from '@/components/Layout';
import { CountrySelector } from '@/components/country-selection/CountrySelector';

export type LanguagesSelectorPageProps = {
  localization: LocalizationInfo;
  translations: Translations;
};

export const getStaticProps: GetStaticProps<LanguagesSelectorPageProps> =
  async () => {
    const locProps = await getLocalizedPageProps(
      // Here I'm hardcoding some fake location, just to have access to localization object in my page props
      { language: 'en', country: 'fr' },
      draftEnabled,
    );

    return {
      props: {
        ...locProps,
      },
    };
  };

const LanguagesSelectorPage = ({
  localization,
  localization: { localizations },
  translations,
}: LanguagesSelectorPageProps) => {
  const options = useMemo(
    () =>
      (localizations || []).map(loc => ({
        flag: `${countryCodeToFlagEmoji(loc.country)}`,
        label: `${loc.label}`,
        value: `${localizationToString(loc)}`,
      })),
    [localizations],
  );
  return (
    <Layout localization={localization} translations={translations}>
      <CountrySelector options={options} />
    </Layout>
  );
};

export default LanguagesSelectorPage;
