import styled from 'styled-components';
import { Heading } from '@heetch/flamingo-react';
import { theme } from '@styles/theme';

export const LargeHeading = styled(Heading)`
  color: currentColor;
  font-weight: 900;
  letter-spacing: 0.04em;
  margin: 0;
  // max-width: calc(100% - 2 * 70px);
  // white-space: wrap;
  // width: auto;

  font-size: 100px;
  line-height: 81px;

  // overflow-wrap: break-word;
  // word-wrap: break-word;
  // -webkit-hyphens: auto;
  // -ms-hyphens: auto;
  // -moz-hyphens: auto;
  // hyphens: auto;

  ${theme.media.tablet} {
    font-size: 80px;
    line-height: 68px;
  }

  ${theme.media.mobileUp} {
    font-size: 44px;
    line-height: 40px;
  }
`;
